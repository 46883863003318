import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['trigger', 'form']

  connect() {
    setTimeout(
      function() {
        this.triggerTarget.select.select.on(
          'item_add',
          function(value, item) {
            const count = document.querySelector('[data-controller~="selection"]').selection.count
            Rails.ajax({
              type: 'GET',
              url: `/customers/campaign_prospects/distribution?campaign_id=${value}&count=${count}`,
              dataType: 'json',
              success: ((data) => {
                this.formTarget.innerHTML = data.html
              })
            })
        }.bind(this))
      }.bind(this),
      300
    )
  }
}
