import { Controller } from "@hotwired/stimulus"
import consumer from "channels/consumer";

export default class extends Controller {
  static targets = ["initMessage", "waitMessage", "successMessage", "link", "actions", "uuid"]

  fillInitMessage() {
    this.itemsCount = this.getItemsCount()
    document.getElementById("init-message-title").innerHTML = `You have selected ${this.itemsCount} rows.`
    if (this.itemsCount == 0) {
      document.getElementById("init-message-subtitle").innerHTML = `Export is impossible.`
      document.getElementById("modal-export-button").disabled = true
    } else if (this.itemsCount > 10000) {
      document.getElementById("init-message-subtitle").innerHTML = `Only the first 10 000 will be exported. Do you want to continue?`
    } else {
      document.getElementById("init-message-subtitle").innerHTML = `Do you want to continue?`
    }
  }

  createConnection() {
    this.uuid = crypto.randomUUID()
    this.uuidTarget.value = this.uuid
    this.initMessageTarget.classList.add('hidden')
    this.waitMessageTarget.classList.remove('hidden')
    this.actionsTarget.classList.add('hidden')

    this.channel = consumer.subscriptions.create(
      {
        channel: 'ExportCsvChannel',
        uuid: this.uuid
      },
      {
        received: this._received.bind(this)
      }
      )
  }

  _received(data) {
    this.channel.unsubscribe()
    this.waitMessageTarget.classList.add('hidden')
    this.linkTarget.innerHTML = data.html
    this.successMessageTarget.classList.remove('hidden')
  }

  getItemsCount() {
    let count = null
    if (document.querySelector('span[data-selection-target="count"]') !== null ) {
      count = document.querySelector('span[data-selection-target="count"]').innerText
      if (count === "") {
        count = document.querySelector('span[data-selection-target="total"]').innerText
      }
    } else {
      count = document.querySelector('span[data-pagination-target="total"]').innerText
    }
    return parseInt(count.split(',').join(''))
  }
}
