import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static values = { url: String }
  static targets = ["wrapper"]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
  }

  open(e) {
    e.preventDefault()
    e.target.blur()
    // // Lock the scroll and save current scroll position
    this.lockScroll()

    Rails.ajax({
      type: 'GET',
      url: e.currentTarget.dataset.url,
      dataType: 'json',
      success: ((data) => {
        // insert the modal content
        this.wrapperTarget.innerHTML = data.html
        this.modalId = this.wrapperTarget.querySelector('[data-modal]').id
        // Unhide the modal
        document.getElementById(this.modalId).classList.remove('hidden')
        // Insert the background
        document.body.insertAdjacentHTML('beforeend', this.backgroundHtml())
        this.background = document.querySelector(`#modal-background`)
      }).bind(this)
    })
  }

  close(e) {
    if (e) {
      e.preventDefault()
    }

    // Unlock the scroll and restore previous scroll position
    this.unlockScroll()

    // Hide the modal
    // => add hidden on currently open modal
    document.getElementById(this.modalId).remove()

    // Remove the background
    if (this.background) { this.background.remove() }
  }

  closeBackground(e) {
    if (e.target.id == this.modalId) {
      this.close(e)
    }
  }

  closeWithKeyboard(e) {
    if (e.keyCode === 27 && !document.getElementById(this.modalId).classList.contains('hidden')) {
      this.close(e);
    }
  }

  backgroundHtml() {
    return '<div data-action="keyup@window->async-modal#closeWithKeyboard" id="modal-background" class="backdrop-blur fixed top-0 left-0 w-full h-full" style="background-color: rgba(19, 20, 21, 0.5); z-index: 9998;"></div>';
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition()

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden')

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden')

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition()

    // Remove the negative top inline style from body
    document.body.style.top = null
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition
  }
}
