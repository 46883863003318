import { Controller } from "@hotwired/stimulus"
import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default class extends Controller {
  static targets = ['body', 'trigger']

  connect() {
    if (!this.bodyTarget.innerHTML.trim()) {
      return
    }
    tippy(this.triggerTarget, {
      content: this.bodyTarget.innerHTML,
      allowHTML: true,
      placement: 'bottom'
    })
  }
}
