import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'content',
    'row'
  ]

  static values = {
    open: Boolean,
    openedUrl: String
  }

  rowOrigin = null

  open(event) {
    if (event.target.matches('[type="checkbox"]')) return

    const url = event.currentTarget.dataset.url

    if (this.openValue && this.openedUrl == url) {
      this.close()
      return
    }
    this.openedUrl = url

    if (this.hasRowTarget) {
      this.close()
    }

    this.rowOrigin = event.currentTarget
    this.rowOrigin.classList.add('border', 'border-orange-500')

    this.openValue = true

    const cellsCount = event.currentTarget.cells.length
    const subtable = `
      <tr data-subtable-target="row">
        <td class="p-0" colspan="${cellsCount}" data-subtable-target="content">
        </td>
      </tr>
    `
    event.currentTarget.insertAdjacentHTML('afterend', subtable)

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.contentTarget.innerHTML = data.html
      }
    })
  }

  close() {
    this.openValue = false
    this.rowTarget.remove()
    this.openedUrl = null
    this.rowOrigin.classList.remove('border', 'border-orange-500')
    this.rowOrigin = null
  }
}
