import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['card']
  static values = {
    selectedIndex: { type: Number, default: 0 }
  }

  activateCard(e) {
    this.currentCard = e.currentTarget

    this.cardTargets.forEach((card) => {
      card.childNodes[1].classList.remove('shadow-border')
    })

    this.currentCard.childNodes[1].classList.add('shadow-border')
    this.selectedIndexValue = this.cardTargets.indexOf(this.currentCard)
  }

  previous(event) {
    if (event.key == 'ArrowLeft' && this.selectedIndexValue != 0) {
      const previousIndex = this.selectedIndexValue - 1
      this.cardTargets[previousIndex].focus()
      this.cardTargets[previousIndex].click()
    }
  }

  next(event) {
    if (event.key == 'ArrowRight' && this.selectedIndexValue + 1 != this.cardTargets.length) {
      const nextIndex = this.selectedIndexValue + 1
      this.cardTargets[nextIndex].focus()
      this.cardTargets[nextIndex].click()
    }
  }
}
