import { Controller } from "@hotwired/stimulus"
import tinymce from 'tinymce'
/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default'

/* A theme is also required */
import 'tinymce/themes/silver'

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css'

/* Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'
import 'tinymce/plugins/image'

export default class extends Controller {
  initialize() {
    tinymce.remove()
    this.initTiny(this.element)
  }

  initTiny(element) {
    tinymce.init({
      target: element,
      plugins: 'advlist code emoticons link lists table image',
      height: 350,
      skin: false,
      content_css: false,
      statusbar: false,
      menubar: false,
      toolbar: 'fontselect fontsizeselect forecolor | bold italic underline | link unlink | image | alignleft aligncenter alignright | table | code'
    })
  }
}
