import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  follow(event) {
    const url =
      event.currentTarget.dataset.link ? event.currentTarget.dataset.link : event.currentTarget.dataset.url
    const newUrl = window.location.protocol + '//' + window.location.host + url
    document.location.assign(newUrl)
  }

  searchRedirect(event) {
    event.preventDefault()
    const dataset = event.currentTarget.dataset
    const search = JSON.parse(dataset.search)
    const type = dataset.to
    const searches = [
      {
        id: "all",
        name: "All",
        payload: search
      }
    ]
    localStorage.setItem(`search_${type}`, JSON.stringify(searches))
    window.location.href = type
  }

  searchesRedirect(event) {
    event.preventDefault()
    const dataset = event.currentTarget.dataset
    const searches = JSON.parse(dataset.searches)
    const type = dataset.to
    localStorage.setItem(`search_${type}`, JSON.stringify(searches))
    window.location.href = type
  }
}
