// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "./application.scss"

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "@hotwired/turbo-rails"
// import * as Turbo from "@hotwired/turbo"

// require("trix")
// require("@rails/actiontext")
import tinymce from 'tinymce'

/* Default icons are required for TinyMCE 5.3 or above */
import 'tinymce/icons/default'

/* A theme is also required */
import 'tinymce/themes/silver'

/* Import the skin */
import 'tinymce/skins/ui/oxide/skin.css'

/* Import plugins */
import 'tinymce/plugins/advlist'
import 'tinymce/plugins/code'
import 'tinymce/plugins/emoticons'
import 'tinymce/plugins/emoticons/js/emojis'
import 'tinymce/plugins/link'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/table'


tinymce.init({
  selector: 'textarea.tinymce',
  plugins: 'advlist code emoticons link lists table',
  height: 350,
  skin: false,
  content_css: false,
  statusbar: false,
  menubar: false,
  toolbar: 'fontselect fontsizeselect forecolor | bold italic underline | link unlink | alignleft aligncenter alignright | table | code'
});

import "channels"

/* Hotwire StimulusJS */
import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import Dropdown from 'stimulus-dropdown'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'

window.Stimulus = Application.start()
const context = require.context("../controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))
window.Stimulus.register('dropdown', Dropdown)
window.Stimulus.register('checkbox-select-all', CheckboxSelectAll)

Rails.start()
ActiveStorage.start()


document.addEventListener('trix-paste', function (event) {
  let div = document.createElement("div")
  div.innerHTML = event.paste.string
  event.target.editor.loadHTML(event.paste.string)
})

function appendRowToList(row) {
  var list = document.querySelector('[data-index-list]')
  list.insertAdjacentHTML('afterbegin', row)
}

window.appendRowToList = appendRowToList

function replaceList(rows) {
  var list = document.querySelector('[data-index-list]')
  list.innerHTML = rows
}
window.replaceList = replaceList

window.addEventListener('turbo:before-render', function () {
  window.zEACLoaded = undefined
})
