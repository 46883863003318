import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  values = {}

  connect() {
    document.querySelectorAll('[data-action="conditional-visibility#click"]').forEach(element => {
      if (element.checked) {
        this.values[this.toName(element)] = element.value
        this.toggle(element, { connecting: true })
      }
    })
  }

  click(event) {
    this.toggle(event.currentTarget)
  }

  toggle(element, { connecting = false }={}) {
    const name = this.toName(element)
    const value = element.value
    this.values[name] = value

    document.querySelectorAll(`[data-show-condition]`).forEach(element => {
      let condition = element.dataset.showCondition
      condition = condition.replace(/(\w*=='\w*')/g, `this.values.$1`)
      if ((eval(condition))) {
        element.classList.remove('hidden')
        this.enableFormInput(element)
      } else {
        element.classList.add('hidden')
        this.disableFormInput(element)
        if (!connecting) {
          this.resetFormInput(element)
        }
      }
    }, this)
  }

  resetFormInput(element) {
    element.querySelectorAll('input[type="checkbox"]').forEach(element => { element.checked = false })
    element.querySelectorAll('input[type="radio"]').forEach(element => { element.checked = false })
    // Do not reset the value, let the user do it if he want to remove it
    // element.query SelectorAll('input[type="text"]').forEach(element => { element.value = '' })
    /*element.querySelectorAll('select').forEach(element => { element.selectedIndex = 0 })
    element.querySelectorAll('select[data-controller="select"]').forEach(element => {
      if (element.tomselect) { element.tomselect.clear() }
    })*/
  }

  disableFormInput(element) {
    element.querySelectorAll('input').forEach(element => {
      element.disabled = true

      if (element.tomselect) {
        element.tomselect.disable()
      }
    })
    element.querySelectorAll('select').forEach(element => {
      element.disabled = true

      if (element.tomselect) {
        element.tomselect.disable()
      }
    })
  }

  enableFormInput(element) {
    element.querySelectorAll('input').forEach(element => {
      element.disabled = false

      if (element.tomselect) {
        element.tomselect.enable()
      }
    })
    element.querySelectorAll('select').forEach(element => {
      element.disabled = false;

      if (element.tomselect) {
        element.tomselect.enable()
      }
    })
  }

  toName(element) {
    return element.name.replace(/^.*\[(.*)\]$/, '$1')
  }
}
