import Dropdown from 'stimulus-dropdown'

export default class extends Dropdown {
  static targets = [
    'button',
    'link'
  ]

  static classes = [
    'active',
    'inactive'
  ]

  active = false

  connect() {
    super.connect()
    const that = this

    this.linkTargets.forEach(link => {
      if (link.classList.contains('active')) {
        that.buttonTarget.innerHTML = `More (${link.innerHTML})`
        that.active = true
      }
    })

    if (this.active) {
      that.buttonTarget.classList.add(...this.activeClasses)
      that.buttonTarget.classList.remove(...this.inactiveClasses)
    } else {
      that.buttonTarget.classList.remove(...this.activeClasses)
      that.buttonTarget.classList.add(...this.inactiveClasses)
    }
  }

  toggle(event) {
    super.toggle()
  }

  hide(event) {
    super.hide(event)
  }
}
