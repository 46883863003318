import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["parent", "child", "counter"]

  connect() {
    this.childToggle()
    this.countChecked()
  }

  parentToggle() {
    if (this.parentTarget.checked) {
      this.childTargets.forEach(child => {
        child.checked = true
      })
    } else {
      this.childTargets.forEach(child => {
        child.checked = false
      })
    }
    this.countChecked()
  }

  childToggle() {
    if (this.childTargets.every(element => element.checked === true)) {
      this.parentTarget.checked = true
      this.parentTarget.indeterminate = false
    } else if (this.childTargets.every(element => element.checked === false)) {
      this.parentTarget.checked = false
      this.parentTarget.indeterminate = false
    } else {
      this.parentTarget.checked = false
      this.parentTarget.indeterminate = true
    }
    this.countChecked()
  }

  countChecked() {
    if (this.childTargets.every(element => element.checked === true)) {
      this.counterTarget.innerText = "All selected"
      this.counterTarget.classList.add('text-black')
      this.counterTarget.classList.remove('text-gray-400')
    } else if (this.childTargets.every(element => element.checked === false)) {
      this.counterTarget.innerText = "None selected"
      this.counterTarget.classList.add('text-gray-400')
      this.counterTarget.classList.remove('text-black')
    } else {
      const total = this.childTargets.length
      const selected = this.childTargets.filter(element => element.checked === true).length
      this.counterTarget.innerText = `${selected}/${total} selected`
      this.counterTarget.classList.add('text-black')
      this.counterTarget.classList.remove('text-gray-400')
    }
  }
}
