import { Controller } from "@hotwired/stimulus"
import moment from 'moment'
import Rails from '@rails/ujs'
import { useDebounce } from 'stimulus-use'

export default class extends Controller {
  static targets = ['checkbox', 'campaignsButtonLabel', 'startDate', 'endDate', 'period', 'chart']
  static values = {
    campaignIds: Array,
    displayedCampaignIds: Array
  }
  static debounces = ['changeFilters']

  currentValues = {}

  connect() {
    useDebounce(this)
    this.setYear()
  }

  setYear() {
    this.endDateTarget.value = moment().format("yyyy-MM-DD")
    this.startDateTarget.value = moment().subtract(12, 'months').format("yyyy-MM-DD")
    this.changeFilters()
  }

  setYTD() {
    this.endDateTarget.value = moment().format("yyyy-MM-DD")
    this.startDateTarget.value = moment().startOf('year').format("yyyy-MM-DD")
    this.changeFilters()
  }

  set6months() {
    this.endDateTarget.value = moment().format("yyyy-MM-DD")
    this.startDateTarget.value = moment().subtract(6, 'months').format("yyyy-MM-DD")
    this.changeFilters()
  }

  changeFilters() {
    const campaigns = this.selectedCampaigns
    this.displayedCampaignIdsValue = campaigns
    if (this.hasCampaignsButtonLabelTarget) {
      this.updateButtonLabel()
    }
    const period = this.periodTarget.value
    if (Date.parse(this.endDateTarget.value) < Date.parse(this.startDateTarget.value)) { this.endDateTarget.value = this.startDateTarget.value }
    const startDate = this.startDateTarget.value
    const endDate = this.endDateTarget.value
    const newValues = {
      campaigns: campaigns,
      period: period,
      startDate: startDate,
      endDate: endDate
    }
    if (JSON.stringify(this.currentValues) == JSON.stringify(newValues)) {
      return
    }

    if (startDate != "" && endDate != "" && period != "") {
      this.chartTargets.forEach(target => this.refresh(target))
    }
    this.currentValues = newValues
  }

  buildUrl(target) {
    return `${target.dataset.url}?start_date=${this.startDateTarget.value}&end_date=${this.endDateTarget.value}&period=${this.periodTarget.value}&campaign_ids=${this.displayedCampaignIdsValue.join(',')}`
  }

  refresh(target) {
    Rails.ajax({
      url: this.buildUrl(target),
      type: "GET",
      dataType: "json",
      success: (data) => {
        target.outerHTML = data.html
      }
    })
  }

  updateButtonLabel() {
    if (this.allCampaignsAreSelected) {
      this.campaignsButtonLabelTarget.innerHTML = "All campaigns selected"
    } else if (this.selectedCampaigns.length <= 1) {
      this.campaignsButtonLabelTarget.innerHTML = `${this.selectedCampaigns.length} campaign selected`
    } else {
      this.campaignsButtonLabelTarget.innerHTML = `${this.selectedCampaigns.length} campaigns selected`
    }
  }

  get selectedCampaigns() {
    if (this.hasCheckboxTarget) {
      return this.checkboxTargets.filter(checkbox => checkbox.checked).map(checkbox => parseInt(checkbox.value))
    } else {
      return this.campaignIdsValue
    }
  }

  get allCampaignsAreSelected() {
    return this.selectedCampaigns.length == this.campaignIdsValue.length
  }
}
