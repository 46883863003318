import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ['trigger', 'form']

  connect() {
    let pathName = window.location.pathname
    let newPath = null

    if (pathName.startsWith('/customers') || pathName.startsWith('/campaigns') ) {
      newPath = '/customers'
    } else if (pathName.startsWith('/client')) {
      newPath = '/client'
    }


    setTimeout(
      function () {
        this.triggerTarget.select.select.on(
          'item_add',
          function (value, item) {
            Rails.ajax({
              type: 'GET',
              url: `${newPath}/exclude_items/exclude_item_detail?list_id=${value}`,
              dataType: 'json',
              success: ((data) => {
                this.formTarget.innerHTML = data.html
              })
            })
          }.bind(this))
      }.bind(this),
      300
    )
  }
}
