import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["collapse", "counter", "count", "select", "checkbox", "number", "date", "toggle"]

  config = {
    hiddenClass: "hidden"
  }

  connect() {
    this.count()
  }

  clearSelect(event) {
    const select = document.getElementById(event.currentTarget.dataset.selectId)
    select.tomselect.clear()
  }

  clearValue(event) {
    const input = document.getElementById(event.currentTarget.dataset.valueId)
    input.value = ""
    if (input.type == "datetime-local") {
      const changeEvent = new Event('change')
      input.dispatchEvent(changeEvent)
    }
  }

  reset(event) {
    event.preventDefault()
    event.stopPropagation()

    this.toggleTargets.forEach((toggle) => {
      if (toggle.checked == true) {
        let click = new MouseEvent('click', {
          'view': window,
          'bubbles': true,
          'cancelable': true
        })
        toggle.dispatchEvent(click)
      }
    })
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })

    this.counterTarget.classList.add(this.config.hiddenClass)
    this.element.dataset.count = 0
    this.dispatchUpdateCount()
    this.collapseTarget.click()
  }

  count() {
    let activeFields = 0
    this.selectTargets.forEach((select) => {
      if (select.tomselect)
        activeFields += select.tomselect.items.length
    })
    this.checkboxTargets.forEach((checkbox) => {
      if (checkbox.checked) {
        activeFields += 1
      }
    })

    this.numberTargets.forEach((number) => {
      if (number.value != "") {
        activeFields += 1
      }
    })

    this.dateTargets.forEach((date) => {
      if (date.value != "") {
        activeFields += 1
      }
    })

    if (activeFields > 0) {
      this.counterTarget.classList.remove(this.config.hiddenClass)
      this.countTarget.innerHTML = activeFields
    } else {
      this.counterTarget.classList.add(this.config.hiddenClass)
    }
    this.element.dataset.count = activeFields
    this.dispatchUpdateCount()
  }

  dispatchUpdateCount() {
    let event = new CustomEvent('updatecount');
    this.element.dispatchEvent(event)
  }

}
