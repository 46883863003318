import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "count",
    "tagButton",
    "answersToTagInput"
  ]

  answersToTagWithHistoric = {}
  answersToTag = {}

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    this.initForm()
  }

  storeTag({ detail: { inboxId, currentTagId, newTagId } }) {
    const fullAnswer = {
      [inboxId]: {
        currentTagId: currentTagId,
        newTagId: newTagId
      }
    }

    const answer = { [inboxId]: newTagId }

    if (Object.hasOwn(this.answersToTagWithHistoric, inboxId)) {
      if (newTagId == this.answersToTagWithHistoric[inboxId].currentTagId) {
        this.removeAnswer(inboxId)
      } else {
        this.updateAnswer(inboxId, answer, fullAnswer)
      }
    } else {
      this.addAnswer(answer, fullAnswer)
    }

    this.updateButton(Object.keys(this.answersToTag).length)
    this.updateSelect()
  }

  addAnswer(answer, fullAnswer) {
    Object.assign(this.answersToTagWithHistoric, fullAnswer)
    Object.assign(this.answersToTag, answer)
  }

  updateAnswer(inboxId, answer, fullAnswer) {
    this.answersToTagWithHistoric[inboxId] = fullAnswer[inboxId]
    this.answersToTag[inboxId] = answer[inboxId]
  }

  removeAnswer(inboxId) {
    delete this.answersToTagWithHistoric[inboxId]
    delete this.answersToTag[inboxId]
  }

  initForm() {
    this.answersToTagWithHistoric = {}
    this.answersToTag = {}
    this.answersToTagInputTarget.innerHTML = ""
    this.countTarget.innerHTML = '0'
    this.tagButtonTarget.disabled = true
  }

  updateButton(arraySize) {
    this.countTarget.innerHTML = arraySize
    if (arraySize) {
      this.tagButtonTarget.disabled = false
    } else {
      this.tagButtonTarget.disabled = true
    }
  }

  updateSelect() {
    const select = this.answersToTagInputTarget
    select.innerHTML = ""
    Object.entries(this.answersToTag).forEach(answer => {
      const opt = document.createElement('option')
      opt.value = answer
      opt.innerHTML = answer
      opt.selected = true
      select.appendChild(opt)
    })
  }
}
