import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"
import consumer from "channels/consumer";

export default class extends Controller {
  static values = { url: String, page: Number, channel: String }

  loaded = false

  connect() {
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting === true) {
        if (!this.loaded) {
          this.loadURL()
          this.loaded = true
        }
      }
    }.bind(this), {
      rootMargin: "0px"
    })
    observer.observe(this.element)
    this.paginationController = this.element.closest('[data-controller*="pagination"]')
  }

  loadURL() {
    let event = new CustomEvent('loading')
    this.paginationController.dispatchEvent(event)

    Rails.ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      success: (data) => {
        this.jid = data.jid
        this.subscribe()
      }
    })
  }

  subscribe() {
    this.subscription = consumer.subscriptions.create(
      {
        channel: this.channelValue,
        jid: this.jid
      },
      {
        received: this._received.bind(this)
      }
    )
  }

  disconnect() {
    try {
      if (this.subscription) {
        this.subscription.unsubscribe()
        this.subscription.perform("cancel", { jid: this.jid })
      }
    } catch (error) {
      console.log(error)
    }
  }

  _received(data) {
    try {
      let event = new CustomEvent('pagination', {
        detail: {
          total: data.total,
          shown: data.shown
        }
      })
      if (data.headers) {
        document.getElementById('table-header').innerHTML = data.headers
      }
      if (this.element) {
        this.element.outerHTML = data.entries
        this.paginationController.dispatchEvent(event)
      }
    } catch(error) {
      console.log("lazy loading stopped", error)
    } finally {
      this.subscription.unsubscribe()
    }

  }
}
