import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["panel", "chevron", "checkbox", "field"]

  config = {
    hiddenClass: "hidden",
    rotationClass: "rotate-180"
  }

  connect() {
    if (this.hasCheckboxTarget && (this.checkboxTarget.checked || this.checkboxTarget.indeterminate)) {
      this.panelTarget.classList.remove(this.config.hiddenClass)
    }
  }

  toggle(event) {
    this.panelTarget.classList.toggle(this.config.hiddenClass)
    if (this.hasChevronTarget) {
      this.chevronTarget.classList.toggle(this.config.rotationClass)
    }
  }

  focus(event) {
    if (event.currentTarget.checked) {
      if (this.fieldTarget.classList.contains('tomselected')) {
        this.fieldTarget.nextElementSibling.querySelector('input').focus()
      } else {
        this.fieldTarget.focus()
      }
    }
  }
}
