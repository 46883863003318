import { Controller } from "@hotwired/stimulus"
import { Util } from "helpers/util"
import { useDebounce } from 'stimulus-use'
import qs from "qs"
import dig from 'object-dig'

export default class extends Controller {
  static targets = ["searchPanel", "table", "show", "hide", "form", "list"]
  static values = { id: String, type: String }
  static debounces = ['submit']

  submitable = true
  currentQuery = {}
  config = {
    open: false
  }

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    useDebounce(this)

    this.config.open = Util.toBoolean(localStorage.getItem(this.idValue))
    this.toggle(this.config.open)
    this.order = []

    // let params = (new URL(document.location)).searchParams
    // let currentColumn = params.get("order[column]")
    // let currentDirection = params.get("order[direction]")

    // if (currentColumn && currentDirection) {
    //   this.order = {
    //     column: currentColumn,
    //     direction: currentDirection
    //   }
    // }
  }

  get currentTab() {
    return this.element.savedSearch.currentTab
  }
  get searches() {
    const type = window.location.pathname
    return JSON.parse(localStorage.getItem(`search_${type}`))
  }

  // setSearches(searches) {
  //   const type = window.location.pathname
  //   localStorage.setItem(`search_${type}`, JSON.stringify(searches))
  // }

  get currentParams() {
    const currentSearches = this.searches
    const currentParamsIndex = currentSearches.findIndex(search => search.id == this.currentTab)
    return currentSearches[currentParamsIndex].payload
  }

  setCurrentTabPayload(payload) {
    const currentSearches = this.searches
    const currentParamsIndex = currentSearches.findIndex(search => search.id == this.currentTab)
    currentSearches[currentParamsIndex].payload = payload
    return currentSearches
  }

  get currentOrder() {
    return this.currentParams.order || []
    // return {
    //   direction: dig(this.currentParams, 'order', 'direction'),
    //   column: dig(this.currentParams, 'order', 'column')
    // }
  }

  resetOrderDisplay() {
    const directions = ['asc', 'desc']
    directions.forEach(direction => {
      document.querySelectorAll(`svg[data-direction]`).forEach(svg => {
        svg.classList.add('hidden')
      })
    })
    document.querySelectorAll(`svg[data-direction="undefined"]`).forEach(svg => {
      svg.classList.remove('hidden')
    })
  }

  setOrder(event = null) {
    if (event) {
      event.preventDefault()
    }

    let direction = event.currentTarget.dataset.direction || 'asc'
    let column = event.currentTarget.dataset.column

    let currentColumn = this.currentOrder.find(order => order.column == column)
    let currentColumnIndex = this.currentOrder.findIndex(order => order.column == column)
    if (currentColumn && currentColumn.column == column) {
      switch (currentColumn.direction) {
        case 'undefined':
          direction = 'asc'
          break
        case 'asc':
          direction = 'desc'
          break
        default:
          direction = 'undefined'
      }
    }

    if (currentColumn) {
      if (direction == 'undefined') {
        this.order = this.order.filter((value, _index, _arr) => {
          return value.column != column
        })
      } else {
        this.order[currentColumnIndex].direction = direction
      }
    } else {
      if (direction != 'undefined') {
        this.order.push({
          column: column,
          direction: direction
        })
      }
    }

    // display
    let headerElement = document.querySelector(`[data-column="${column}"]`)
    headerElement.querySelectorAll('svg[data-direction]').forEach(svg => {
      svg.classList.add('hidden')
    })
    headerElement.querySelector(`svg[data-direction="${direction}"]`).classList.remove('hidden')

    this.submit()
  }

  get filtersFormData() {
    const formData = new FormData(this.formTarget)
    const newFormData = new FormData()

    for (var pair of formData.entries()) {
      if (!(pair[1] == 0 || pair[1] == "")) {
        newFormData.append(pair[0], pair[1])
      }
    }
    if (this.order.length > 0) {
      this.order.forEach((order) => {
        newFormData.append(`order[${order.column}]`, order.direction)
      })
    }

    return newFormData
  }

  submit(event) {
    if (!this.submitable) { return }

    if (event) {
      event.preventDefault()
    }

    let formTarget = this.formTarget.action.split('?')[0]
    let newFormData = this.filtersFormData

    const queryString = new URLSearchParams(newFormData).toString()
    if (this.currentQuery != qs.parse(queryString)) {
      this.currentQuery = qs.parse(queryString)
      document.body.dispatchEvent(new CustomEvent('filters:submit', { detail: this.currentQuery }))
      let url = formTarget
      let html = `
        <tr
          data-controller="search-loader"
          data-search-loader-page-value="1"
          data-search-loader-url-value="${url}"
          data-search-loader-params-value="${this.currentQuery}"
        </tr>
      `

      if (this.hasTypeValue && this.typeValue == 'cardsTable') {
        html = `
          <div
            data-controller="search-loader"
            data-search-loader-page-value="1"
            data-search-loader-url-value="${url}"
            data-search-loader-url-value="${this.currentQuery}"
          </div>
        `
      }
      this.listTarget.innerHTML = html
    }
  }

  // panel stuff

  toggle(state) {
    if (state) {
      this.close()
    } else {
      this.open()
    }
  }

  click(event) {
    this.config.open = !this.config.open
    this.toggle(this.config.open)
    localStorage.setItem(this.idValue, this.config.open)
  }

  close() {
    this.tableTarget.classList.remove('left-72')
    this.searchPanelTarget.classList.add('-translate-x-72')
    if (this.hasHideTarget) {
      this.hideTarget.classList.add('hidden')
    }
    if (this.hasShowTarget) {
      this.showTarget.classList.remove('hidden')
    }
  }

  open() {
    this.tableTarget.classList.add('left-72')
    this.searchPanelTarget.classList.remove('-translate-x-72')
    if (this.hasHideTarget) {
      this.hideTarget.classList.remove('hidden')
    }
    if (this.hasShowTarget) {
      this.showTarget.classList.add('hidden')
    }
  }

  blockEnterKeyBehavior(event) {
    if (event.keyIdentifier == 'U+000A' || event.keyIdentifier == 'Enter' || event.keyCode == 13) {
      event.preventDefault()
    }
  }
}
