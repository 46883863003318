import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["total", "count", "pill"]

  connect() {
    this.total()
  }

  total() {
    let filters = 0
    this.countTargets.forEach((element) => {
      filters += parseInt(element.dataset.count)
    })
    this.totalTarget.innerHTML = filters
    if (filters > 0) {
      this.pillTarget.classList.add('bg-orange-500')
      this.pillTarget.classList.remove('bg-gray-200')
    } else {
      this.pillTarget.classList.remove('bg-orange-500')
      this.pillTarget.classList.add('bg-gray-200')
    }
  }
}
