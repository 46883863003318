import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = ["select", "close", "submit", "form", "searchParams"]

  get atLeastOneTag() {
    return this.selectTarget.tomselect.items.length > 0
  }

  change() {
    if (this.atLeastOneTag) {
      this.submitTarget.disabled = false
    } else {
      this.submitTarget.disabled = true
    }
  }

  submit(e) {
    e.preventDefault()
    let fd = new FormData(this.formTarget)
    Rails.ajax({
      type: 'POST',
      url: this.formTarget.action,
      data: fd,
      dataType: 'json',
      success: (data) => {
        this.formTarget.innerHTML = data.html
        document.body.selection.resetSelection()
      }
    })
  }
}
