import TomSelect from 'tom-select'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    tags: Array,
    categories: Array,
    inbox: String,
    currentTag: String,
    selected: String
  }

  static targets = [ 'inboxTagController' ]

  select = null
  value = null
  categories = [
    'acknowledge',
    'follow_up_later',
    'not_applicable',
    'opt_out',
    'qualify',
    'understand_objection',
    'verify_fit'
  ]

  connect() {
    this.select = new TomSelect(this.element, {
      placeholder: 'Choose a tag',
      options: this.tagsValue,
      optgroups: this.categoriesValue,
      optgroupField: 'email_category',
      valueField: 'id',
      labelField: 'email_qualification',
      searchField: ['email_qualification'],
      sortField: 'email_category',
      render: {
        option: function(data, escape) {
          return `<div class="option ${escape(data.email_category)}" data-class="${escape(data.email_category)}">` + escape(data.email_qualification) + '</div>'
        }
      },
      onDropdownClose:function(){
        this.input.closest('a').focus()
      }
    })

    this.select.setValue(this.selectedValue)
    this.updateSelectStyle(this.selectedValue)
  }

  updateSelectStyle(value) {
    if (value) {
      const selectedClass = this.select.getOption(value).dataset.class
      const selectedItem = this.select.getItem(value)
      const inputItems = selectedItem.parentElement
      const categories = this.categories

      // Cleanup class
      inputItems.classList.forEach(elementClass => {
        if (categories.includes(elementClass)) { inputItems.classList.remove(elementClass) }
      })

      inputItems.classList.add(selectedClass)
    }
  }

  changeSelectedValue(event) {
    if (event.target.value != this.selectedValue) {
      this.selectedValue = event.target.value
      this.updateSelectStyle(this.selectedValue)
      this.dispatch('changeSelectedValue', { detail: { inboxId: this.inboxValue, currentTagId: this.currentTagValue, newTagId: this.selectedValue }})
    }
  }

}
