import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  toCamel(s) {
    return s.replace(/([-_\.][a-z])/ig, ($1) => {
      return $1.toUpperCase()
        .replace('-', '')
        .replace('.', '')
        .replace('_', '')
    })
  }

  connect() {
    if (!this.element.closest('[data-controller~="saved-search"]')) { return }

    this.savedSearch = this.element.closest('[data-controller~="saved-search"]').savedSearch
    const order = this.savedSearch.getCurrentOrder()
    const currentOrder = order[0]
    if (currentOrder === undefined) { return }

    const isGroupable = !!document.querySelector(`[data-header="${currentOrder.column}"][data-groupable]`)
    if (isGroupable === false) { return }

    const fieldName = this.toCamel(currentOrder.column)

    const value = this.element.dataset[fieldName]
    if (value === undefined) { console.log(`Missing value for ${fieldName}`) }

    const field = currentOrder.column.replace('.', '-')
    if (this.element == document.querySelector(`tr[data-${field}="${value}"]`)) {
      this.insertHeaderBefore(value)
    }
  }

  insertHeaderBefore(value) {
    this.element.insertAdjacentHTML('beforeBegin', `
      <tr class="bg-gray-200 relative" data-group>
        <td colspan="100" class="sticky left-0 text-xs px-2 py-2 text-gray-900 font-medium">
          ${value}
        </td>
      </tr>
    `)
  }
}
