import { Controller } from "@hotwired/stimulus"
import { Util } from "helpers/util"

export default class extends Controller {
  static targets = ['hideable', 'showable', 'link', 'header', 'button', 'centerable']

  config = {
    expanded: true,
    hiddenClass: 'hidden'
  }

  connect() {
    this.config.expanded = Util.toBoolean(localStorage.getItem('sidebarOpen'))
    this.setState()
  }

  setState() {
    if (this.config.expanded) {
      this._minimize()
    } else {
      this._expand()
    }
  }

  toggle() {
    this.config.expanded = !this.config.expanded
    this.setState()
    localStorage.setItem('sidebarOpen', this.config.expanded)
  }

  _minimize() {
    this.linkTargets.forEach((target) => {
      target.classList.add('justify-center')
    })
    this.centerableTargets.forEach((target) => {
      target.classList.add('justify-center')
    })
    this.hideableTargets.forEach((target) => {
      target.classList.add(this.config.hiddenClass)
    })
    this.showableTargets.forEach((target) => {
      target.classList.remove(this.config.hiddenClass)
    })
    this.headerTarget.classList.remove('justify-between')
    this.headerTarget.classList.add('justify-center')
    this.buttonTarget.classList.add('rotate-180')
    this.element.classList.remove('w-60')
    this.element.classList.add('w-22')
  }

  _expand() {
    this.linkTargets.forEach((target) => {
      target.classList.remove('justify-center')
    })
    this.centerableTargets.forEach((target) => {
      target.classList.remove('justify-center')
    })
    this.hideableTargets.forEach((target) => {
      target.classList.remove(this.config.hiddenClass)
    })
    this.showableTargets.forEach((target) => {
      target.classList.add(this.config.hiddenClass)
    })
    this.headerTarget.classList.add('justify-between')
    this.headerTarget.classList.remove('justify-center')
    if (this.hasButtonTarget) {
      this.buttonTarget.classList.remove('rotate-180')
    }
    this.element.classList.add('w-60')
    this.element.classList.remove('w-22')
  }
}
