import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    'spinner',
    'panel',
    'table',
    'content',
    'defaultContent',
    'dropdownContent',
    'dropdownButton'
  ]

  static values = {
    open: Boolean,
    openDropdown: Boolean
  }

  rowOrigin = null

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    this.openDropdownValue = true
  }

  open(event) {
    event.stopPropagation()

    if (event.currentTarget.tagName === 'TR') {
      this.currentRow = event.currentTarget
    } else if (event.currentTarget.dataset.rowId) {
      this.currentRow = document.getElementById(event.currentTarget.dataset.rowId)
    } else {
      this.currentRow = event.currentTarget.closest('tr')
    }

    if (event.target.matches('[type="checkbox"]')) return

    this.contentTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')

    this.panelTarget.classList.add('right-0')
    this.panelTarget.classList.remove('-right-80')
    this.tableTarget.classList.add('right-80')

    if (this.hasDropdownButtonTarget && this.dropdownContentTarget.childElementCount > 0) {
      this.dropdownButtonTarget.classList.remove('hidden')
      this.openDropdownValue = false
      this.setDropdown()
    }

    if (this.rowOrigin) {
      this.rowOrigin.classList.remove('shadow-border')
    }
    if ((this.rowOrigin == event.currentTarget) && (this.openValue == true)) {
      this.currentRow.classList.remove('shadow-border')
      this.close()
    } else {
      this.currentRow.classList.add('shadow-border')
      this.openValue = true
    }

    this.rowOrigin = this.currentRow

    const url = event.currentTarget.dataset.url

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.contentTarget.innerHTML = data.html
        this.contentTarget.classList.remove('hidden')
        this.spinnerTarget.classList.add('hidden')
      }
    })
  }

  close() {
    this.openValue = false
    this.contentTarget.innerHTML = ""
    this.panelTarget.classList.remove('right-0')
    this.panelTarget.classList.add('-right-80')
    this.tableTarget.classList.remove('right-80')

    this.rowOrigin.classList.remove('shadow-border')
    this.rowOrigin = null

    if (this.hasDropdownButtonTarget && this.dropdownContentTarget.childElementCount > 0) {
      this.dropdownButtonTarget.classList.add('hidden')
      this.openDropdownValue = true

      this.unsetDropdown()
    }
  }

  populate(event) {
    event.stopPropagation()

    this.currentRow = event.currentTarget

    this.contentTarget.classList.add('hidden')
    this.defaultContentTarget.classList.add('hidden')
    this.spinnerTarget.classList.remove('hidden')

    if (this.rowOrigin === null) {
      this.currentRow.classList.add('shadow-border')
    } else if (this.currentRow != this.rowOrigin) {
      this.currentRow.classList.add('shadow-border')
      this.rowOrigin.classList.remove('shadow-border')
    } else if (this.currentRow === this.rowOrigin) {
      this.spinnerTarget.classList.add('hidden')
      this.contentTarget.classList.remove('hidden')
      return
    }

    this.rowOrigin = this.currentRow

    const url = event.currentTarget.dataset.url

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.contentTarget.innerHTML = data.html
        this.contentTarget.classList.remove('hidden')
        this.spinnerTarget.classList.add('hidden')
      }
    })
  }


  unsetDropdown() {
    this.dropdownContentTarget.classList.remove(
      'w-52',
      'flex-col',
      'origin-top-right',
      'absolute',
      'right-0',
      'mt-2',
      'rounded-md',
      'shadow-lg',
      'bg-white',
      'ring-1',
      'ring-black',
      'ring-opacity-5',
      'divide-y',
      'divide-gray-100'
    )
    this.dropdownContentTarget.classList.add('space-x-2')
    this.dropdownContentTarget.querySelectorAll('div[data-controller="modal"]').forEach((button) => {
      button.classList.remove('block')
    })
    this.dropdownContentTarget.querySelectorAll('button').forEach((button) => {
      button.classList.remove('w-full')
      button.classList.add('inline-flex')
      button.classList.remove('flex')
    })
  }

  setDropdown() {
    this.dropdownContentTarget.classList.add(
      'w-52',
      'flex-col',
      'origin-top-right',
      'absolute',
      'right-0',
      'mt-2',
      'rounded-md',
      'shadow-lg',
      'bg-white',
      'ring-1',
      'ring-black',
      'ring-opacity-5',
      'divide-y',
      'divide-gray-100'
      )
    this.dropdownContentTarget.classList.remove('space-x-2')
    this.dropdownContentTarget.querySelectorAll('div[data-controller="modal"]').forEach((button) => {
      button.classList.add('block')
    })
    this.dropdownContentTarget.querySelectorAll('button').forEach((button) => {
      button.classList.add('w-full')
      button.classList.remove('inline-flex')
      button.classList.add('flex')
    })
  }

  toggleDropdown() {
    this.openDropdownValue = !this.openDropdownValue
  }

  hideDropdown(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openDropdownValue = false
    }
  }

  openDropdownValueChanged() {
    if (!this.hasDropdownButtonTarget) return

    if (this.openDropdownValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show() {
    this.dropdownContentTarget.classList.remove('hidden')
  }
  _hide() {
    this.dropdownContentTarget.classList.add('hidden')
  }
}
