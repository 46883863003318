import Highcharts from 'highcharts'
import { Controller } from "@hotwired/stimulus"

// Add custom date formatter for weeks
Highcharts.dateFormats.W = function (timestamp) {
  var date = new Date(timestamp),
    day = date.getUTCDay() === 0 ? 7 : date.getUTCDay(),
    dayNumber

  date.setDate(date.getUTCDate() + 4 - day)
  dayNumber = Math.floor((date.getTime() - new Date(date.getUTCFullYear(), 0, 1, -6)) / 86400000)

  return 1 + Math.floor(dayNumber / 7)
}

export default class extends Controller {

  static values = {
    id: String,
    config: Object
  }

  connect() {
    this.initChart()
  }

  initChart() {
    Highcharts.chart(this.idValue, this.configValue)
  }
}
