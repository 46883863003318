import { Controller } from "@hotwired/stimulus"
import TomSelect from 'tom-select'
import Rails from "@rails/ujs"
import qs from "qs"
import axios from "axios"
import { Util } from "helpers/util"
import dig from 'object-dig'

export default class extends Controller {
  static targets = ['tabs', 'input', 'saveButton', 'updateButton']

  searches = []
  currentTab = null
  pristine = true
  type = this.element.dataset.type

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    if (this.hasInputTarget) {
      this.initTomSelect()
    }
    this.initSearch()
    this.onFilterSubmit()
    this.onApplySavedSearch()
  }

  initSearch() {
    const existingSearches = this.getState()
    if (existingSearches && existingSearches.length > 0) {
      this.searches = existingSearches
      const ids = this.searches.map(search => { return search.id })
      ids.forEach(id => {
        this.displayTab(id)
      })
      this.selectTab('all')
    } else {
      this.reset()
    }
  }

  getSearch(id) {
    return this.searches.find((search) => { return search.id == id })
  }

  displayTab(id) {
    if (this.getTabElement(id)) { return }
    const search = this.getSearch(id)
    this.tabsTarget.insertAdjacentHTML('beforeend', this.tabElement(search))
    this.toggleTabs()
  }

  toggleTabs() {
    if (this.searches.length == 1) {
      this.tabsTarget.classList.add('hidden')
    } else {
      this.tabsTarget.classList.remove('hidden')
    }
  }

  tabElement(search) { // need id and name
    return `
      <div data-saved-search-tab="${search.id}"
          class="flex relative -bottom-px items-center border-b">
        <a class="py-2 pl-3 ${search.id == 'all' ? `font-semibold pr-3`:`pr-1`} hover:no-underline text-gray-600"
            data-id="${search.id}"
            data-action="click->saved-search#clickTab"
            href="#">${search.name}</a>
        ${search.id != 'all' ? `
            <a class="text-gray-400 hover:text-red-600 pr-2 hover:no-underline"
              href="#" data-action="click->saved-search#closeTab" data-id="${search.id}">&times;</a>`
            : ``}
      </div>
    `
  }

  selectTab(id) {
    this.currentTab = id
    const tabElement = this.getTabElement(id)
    const search = this.getSearch(id)

    this.tabsTarget.querySelectorAll('.border-orange-500').forEach(tab => {
      tab.classList.remove('border-orange-500')
    })
    if (tabElement) {
      tabElement.classList.add('border-orange-500')
    }

    // send request to get the new search panel
    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
    axios.post(`${this.type}/search_panel`, { search: search.payload })
      .then(function (response) {
        document.querySelector('[data-controller="search-panel"]').innerHTML = response.data.html
        if (this.hasInputTarget) {
          this.initTomSelect()
        }
        this.element.filters.submit()
      }.bind(this))
  }

  clickTab(event) {
    const id = event.target.dataset.id
    this.selectTab(id)
  }

  getTabElement(id) {
    return this.tabsTarget.querySelector(`[data-saved-search-tab="${id}"]`)
  }

  // whenever filters are submited, toggle update or save buttons, set current Payload for current tab, save state
  onFilterSubmit() {
    document.body.addEventListener('filters:submit', function(event){
      // console.log(JSON.stringify(event.detail.search, 0, 2))
      // console.log(JSON.stringify(this.currentPayload, 0, 2))
      if (this.hasSaveButtonTarget) {
        if (this.currentTab == 'all') {
          this.pristine = (event.detail.search == undefined)
          if (this.pristine) {
            this.saveButtonTarget.classList.add('hidden')
          } else {
            this.saveButtonTarget.classList.remove('hidden')
          }
        } else {
          this.pristine = Util.deepEqual(event.detail.search, this.getSearch(this.currentTab).basePayload)
          if (this.pristine) {
            this.updateButtonTarget.classList.add('hidden')
          } else {
            this.updateButtonTarget.classList.remove('hidden')
          }
        }
      }
      // update currentSearch Payload
      let currentSearch = this.getSearch(this.currentTab)
      currentSearch.payload = event.detail.search

      this.saveState()
    }.bind(this))
  }

  onApplySavedSearch() {
    document.body.addEventListener('saved-search:apply', function(event) {
      const id = event.detail.id
      document.querySelectorAll('[data-saved-search-tab]:not([data-saved-search-tab="all"])').forEach(tab => tab.remove())
      const all = {
        id: 'all',
        name: 'All',
        payload: { saved_search: [id] }
      }
      this.searches = [all]
      this.itemAdd(id)
      this.displayTab('all')
      this.selectTab('all')
    }.bind(this))
  }

  // update filters of a saved search
  updateSavedSearch(event) {
    event.preventDefault()
    const currentId = this.currentTab
    const queryString = new URLSearchParams(this.element.filters.filtersFormData).toString()
    const currentPayload = qs.parse(queryString).search

    const csrfToken = document.querySelector("meta[name=csrf-token]").content
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken
    const data = { saved_search: { payload: currentPayload } }
    axios.patch(`/prospects/saved_searches/${currentId}`, data, { headers: { 'Content-Type': 'application/json', accept: 'application/json' }})
      .then(function (response) {
        var searchIndex = this.searches.findIndex(search => search.id == currentId)
        this.searches[searchIndex].payload = currentPayload
        this.searches[searchIndex].basePayload = currentPayload
        this.selectTab(currentId)
      }.bind(this))
  }

  seeResults(_event) {
    const savedSearchIds = document.body.selection.selection
    const searches = [
      {
        id: "all",
        name: "All",
        payload: { saved_search: savedSearchIds }
      }
    ]
    this.downloadSavedSearches(savedSearchIds, (data) => {
      searches.push(...data)
      localStorage.setItem('search_/prospects', JSON.stringify(searches))
      window.location.href = '/prospects'
    })
  }
  removeTab(id) {
    const searchIndex = this.searches.findIndex(search => search.id == id)
    if (searchIndex > -1) {
      this.searches.splice(searchIndex, 1)
    }

    //remove from all payload
    let allSearch = this.getSearch('all')
    let allSearchPayload = allSearch.payload

    if (allSearchPayload && allSearchPayload.saved_search) {
      const savedSearchIndex = allSearchPayload.saved_search.findIndex(searchId => searchId == id)
      if (savedSearchIndex > -1) {
        allSearchPayload.saved_search.splice(savedSearchIndex, 1)
      }
    }

    this.selectTab('all')
    this.tabsTarget.querySelector(`[data-saved-search-tab="${id}"]`).remove()

    this.toggleTabs()
  }

  closeTab(event) {
    const id = event.target.dataset.id
    this.removeTab(id)
  }

  // should reset this.tabs, set all tab and select all tabs
  reset() {
    const all = {
      id: 'all',
      name: 'All',
      payload: {}
    }
    localStorage.removeItem(`search_${this.type}`)
    document.querySelectorAll('[data-saved-search-tab]:not([data-saved-search-tab="all"])').forEach(tab => tab.remove())
    this.searches = [all]
    this.displayTab('all')
    this.selectTab('all')
    this.toggleTabs()
    this.element.filters.order = []
    this.element.filters.resetOrderDisplay()
  }

  // save this.tabs into localstorage
  saveState() {
    const searches = this.addOrderToCurrentSearch()
    // console.log(this.type, JSON.stringify(this.searches, 0, 2))
    localStorage.setItem(`search_${this.type}`, JSON.stringify(searches))
  }

  addOrderToCurrentSearch() {
    const currentSearches = this.searches
    const currentParamsIndex = currentSearches.findIndex(search => search.id == this.currentTab)
    currentSearches[currentParamsIndex].payload = currentSearches[currentParamsIndex].payload || {}
    currentSearches[currentParamsIndex].payload.order = this.element.filters.order
    return currentSearches
  }

  getCurrentOrder() {
    const currentSearches = this.searches
    const currentParamsIndex = currentSearches.findIndex(search => search.id == this.currentTab)
    return dig(currentSearches, currentParamsIndex, 'payload', 'order') || []
  }

  // get state from
  getState() {
    return JSON.parse(localStorage.getItem(`search_${this.type}`))
  }

  itemAdd(ids) {
    this.downloadSavedSearches(ids, (data) => {
      data.forEach(search => {
        search.basePayload = search.payload
      })

      this.searches.push(...data)
      data.forEach(function(search) {
        this.displayTab(search.id)
      }.bind(this))
    })
  }

  // download saved search details
  downloadSavedSearches(ids, callback) {
    const url = `${document.location.origin}/prospects/saved_searches/tabs?ids=${ids}`

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: function(data) {
        callback(data)
      }.bind(this)
    })
  }

  // when item is removed via tomselect, get id removed and remove tab
  itemRemove(id) {
    if (this.currentTab == 'all') {
      this.removeTab(id)
    }
  }

  initTomSelect() {
    const element = this.inputTarget

    let options = {
      plugins: ['remove_button'],
      highlight: true,
      maxOptions: 300,
      persist: false,
      createFilter: function (input) { return !/^\d+$/g.test(input) } // we don't want names to be integers as we work with ids
    }
    if (element.dataset.labelField) { options.labelField = element.dataset.labelField }
    if (element.dataset.valueField) { options.valueField = element.dataset.valueField }
    if (element.dataset.searchField || element.dataset.labelField) { options.searchField = element.dataset.searchField || element.dataset.labelField }
    if (element.dataset.sortField) { options.sortField = element.dataset.sortField }

    if (element.dataset.url) {
      options.loadThrottle = 500
      options.load = (query, callback) => {
        let url = element.dataset.url + '?q=' + encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(data => {
            if (element.dataset.redefinedValue) {
              data.map(item => item.value = item[element.dataset.redefinedValue])
            }
            callback(data)
          }).catch(() => {
            callback()
          })
      }
    }

    element.select = new TomSelect(element, options)

    if (element.dataset.selected) {
      let selectedData = JSON.parse(element.dataset.selected)

      if (Array.isArray(selectedData)) {
        selectedData.forEach((item) => {
          element.select.addItem(item, true)
        })
      } else {
        element.select.addItem(selectedData, true)
      }
    }

    if (element.dataset.items) {
      JSON.parse(element.dataset.items).forEach((item) => {
        element.select.createItem(item)
      })
    }

    if (element.dataset.wrapperClass) {
      const wrapperClasses = element.dataset.wrapperClass.split(" ")
      element.select.wrapper.classList.add(...wrapperClasses)
    }

    element.select.on('item_add', (id) => {
      this.itemAdd(id)
    })
    element.select.on('item_remove', (id) => {
      this.itemRemove(id)
    })

    element.select.on('item_add', (e) => {
      element.select.control_input.value = null
    })
  }
}
