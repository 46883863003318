import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['panel', 'field', 'excludedSiblings']

  config = {
    hiddenClass: "hidden",
    rotationClass: "rotate-180"
  }

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this
    this.change()
  }

  change(event) {
    if (this.fieldTarget.value == '') {
      this.removeParams()
      this.hide()
    } else {
      if (this.oldValue != this.fieldTarget.value) {
        this.show()
      }
      this.setAutocompleteUrlToExcludedCampaigns()
      this.setExludedSiblings()
    }
    this.oldValue = this.fieldTarget.value
  }

  setAutocompleteUrlToExcludedCampaigns() {
    setTimeout(function() {
      const el = document.getElementById('search_campaign_excluded_campaign_ids')
      let sourcingCampaignId = this.fieldTarget.value
      el.select.element.dataset.url = `${this.element.dataset.autocompleteUrl}/${sourcingCampaignId}`
    }.bind(this), 200)
  }

  setExludedSiblings() {
    const campaignId = this.fieldTarget.value
    const url = `${this.element.dataset.siblingsUrl}?campaign_id=${campaignId}`
    fetch(url)
      .then(response => response.json())
      .then(siblingNames => {
        const siblingsHTML = siblingNames.map(name => {
          return `
            <div>
              <div class="text-xs rounded-md bg-gray-100 px-2 py-0.5 inline-block">
                🔗 ${name}
              </div>
            </div>
          `
        }).join('')
        this.excludedSiblingsTarget.innerHTML = `
          ${siblingsHTML}
        `
      }).catch(() => {
        callback()
      })



  }

  hide(event) {
    this.panelTarget.classList.add(this.config.hiddenClass)
  }
  show(event) {
    this.panelTarget.classList.remove(this.config.hiddenClass)
  }

  removeParams() {
    // TODO: change for localStorage
    const url = new URL(document.location)
    const params = new URLSearchParams(document.location.search)

    params.delete('search[campaign][search_exclusions]')
    params.delete('search[campaign][inclusions]')

    window.history.pushState({}, '', url)
  }
}
