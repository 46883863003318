import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { url: String, page: Number, channel: String }

  connect() {
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting === true) {
        this.loadURL()
      }
    }.bind(this), {
      rootMargin: "0px"
    })
    observer.observe(this.element)
    this.paginationController = this.element.closest('[data-controller*="pagination"]')
  }

  loadURL() {
    let event = new CustomEvent('loading')
    this.paginationController.dispatchEvent(event)
    Rails.ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      success: (data) => {
        if (this.element.parentNode === null) { return }

        let event = new CustomEvent('pagination', {
          detail: {
            total: data.total,
            shown: data.shown
          }
        })
        if (this.element) {
          this.element.outerHTML = data.entries
          this.paginationController.dispatchEvent(event)
          document.querySelectorAll(`#table-header th`).forEach(th => {
            th.style.minWidth = th.clientWidth + 'px'
          })
        }
      }
    })
  }

}
