import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["searchParams"]

  fillSearchParams(e) {
    const filtersController = document.querySelector(`[data-controller~="filters"]`).filters
    const urlParams = new URLSearchParams(filtersController.filtersFormData).toString()
    this.searchParamsTarget.value = urlParams
  }
}
