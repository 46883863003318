import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { url: String, page: Number }

  loaded = false

  connect() {
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting === true) {
        if (!this.loaded) {
          this.loadURL()
          this.loaded = true
        }
      }
    }.bind(this), {
      rootMargin: "0px"
    })
    observer.observe(this.element)
    this.paginationController = this.element.closest('[data-controller*="pagination"]')
  }

  loadURL() {
    let event = new CustomEvent('loading')
    this.paginationController.dispatchEvent(event)
    const url = `${this.urlValue}?page=${this.pageValue}`
    const params = document.querySelector(`[data-controller~="filters"]`).filters.filtersFormData
    Rails.ajax({
      type: 'POST',
      url: url,
      dataType: 'json',
      data: params,
      success: (data) => {
        if (this.element.parentNode === null) { return }

        let event = new CustomEvent('pagination', {
          detail: {
            total: data.total,
            shown: data.shown
          }
        })
        if (this.element) {
          this.element.outerHTML = data.entries
          const selectionController = document.querySelector(`[data-controller~="selection"]`)
          if (selectionController) {
            const selectionMode = selectionController.selection.mode
            if (selectionMode == 'all') {
              document.querySelectorAll('[data-selection-target="list"] input:not(:checked)').forEach(input => {
                input.checked = true
              })
            }
          }

          this.paginationController.dispatchEvent(event)
          // document.querySelectorAll(`#table-header th`).forEach(th => {
          //   th.style.minWidth = th.clientWidth + 'px'
          // })
        }
      }
    })
  }

}
