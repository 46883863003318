import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  VERSION = 1

  connect() {
    if (localStorage.version != this.VERSION) {
      localStorage.clear()
      localStorage.setItem('version', this.VERSION)
    }
  }
}
