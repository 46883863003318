import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["checkbox"]

  connect() {
    this.userChecksFlag = false
    this.userUnchecksFlag = false
    this.lastChecked = null
  }

  toggle(event) {
    if (event.shiftKey && event.currentTarget.checked) {
      this.checkboxTargets.forEach(checkbox => {
        if (checkbox === event.currentTarget || checkbox === this.lastChecked) {
          this.userChecksFlag = !this.userChecksFlag
        }
        if (this.userChecksFlag) {
          checkbox.checked = true
        }
      })
    }

    if (event.shiftKey && !event.currentTarget.checked) {
      this.checkboxTargets.forEach(checkbox => {
        if (checkbox === event.currentTarget || checkbox === this.lastChecked) {
          this.userUnchecksFlag = !this.userUnchecksFlag
        }
        if (this.userUnchecksFlag) {
          checkbox.checked = false
        }
      })
      this.lastChecked.checked = false
    }
    this.lastChecked = event.currentTarget
  }
}
