import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "checkbox",
    "checkAll",
    "list",
    "selected",
    "total",
    "count",
    "ids",
    "multiple",
    "mode",
    "listActionButton"
  ]

  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    this.observeInsertedNodes()
    this.mode = 'none'
    this.selection = []
    this.count = 0
    this.total = 0
  }

  get atLeastOneChecked() {
    return this.listTarget.querySelectorAll('input[data-selection-target="checkbox"]:checked').length > 0
  }

  get moreThanOneChecked() {
    return this.listTarget.querySelectorAll('input[data-selection-target="checkbox"]:checked').length > 1
  }

  get noneChecked() {
    return this.listTarget.querySelectorAll('input[data-selection-target="checkbox"]:checked').length === 0
  }

  get countChecked() {
    if (this.mode == 'all') {
      this.count = this.totalTarget.dataset.count
    }
    else if (this.atLeastOneChecked) {
      count = this.listTarget.querySelectorAll('input[data-selection-target="checkbox"]:checked').length
    }
    return parseInt(count)
  }

  get uncheckedItems() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked == false).map(checkbox => checkbox.value)
  }

  get checkedItems() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked == true).map(checkbox => checkbox.value)
  }

  get allChecked() {
    return this.listTarget.querySelectorAll('input[data-selection-target="checkbox"]:not(:checked)').length === 0
  }


  change(event) {
    // none → check All ⇒ all
    // none → check one ⇒ some
    // all → uncheck all ⇒ none
    // all → uncheck one ⇒ all but
    // some → check all ⇒ all

    // all => search params -> vider checkAllTarget.value
    // some => ids -> replace checkAllTarget.value
    // all but => ids à enlever -> replace checkAllTarget.value

    // should set the master checkbox state (checked, indeterminate)
    // should fill this.selection with: ids, 'all', 'none'

    if (((this.mode == 'all') || (this.mode == 'all_but')) && (event.currentTarget.checked == false)) {
      this.checkAllTarget.indeterminate = true
      this.selection = this.uncheckedItems
      this.mode = 'all_but'
    } else if (this.allChecked) {
      this.checkAllTarget.indeterminate = false
      this.checkAllTarget.checked = true
      // this.mode = 'all'
      this.mode = 'some' // Hack to allow a selection of ids to be passed AND acknowledged/used
      this.selection = this.checkedItems
    } else if ((this.mode == 'all_but') && (event.currentTarget.checked == true)) {
      this.checkAllTarget.indeterminate = true
      this.selection = this.uncheckedItems
      this.mode = 'all_but'
    } else if (this.atLeastOneChecked) {
      this.mode = 'some'
      this.checkAllTarget.indeterminate = true
      this.checkAllTarget.checked = false
      this.selection = this.checkedItems
    } else if (this.noneChecked) {
      this.mode = 'none'
      this.checkAllTarget.indeterminate = false
      this.checkAllTarget.checked = false
      this.selection = []
    } else {
      alert('Please contact technical service, you did something unplanned')
    }

    this.changeSelection()
  }

  observeInsertedNodes() {
    const observer = new MutationObserver((mutations) => {
      this.total = this.totalTarget.dataset.count
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0 && (this.mode === 'all' || this.mode === 'all_but')) {
          mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
              let checkbox = node.querySelector('input[data-selection-target="checkbox"]')
              if (checkbox) {
                checkbox.checked = true
              }
            }
          })
        }
      })
    })
    observer.observe(this.listTarget, {
      childList: true
    })
  }

  change_all(event) {
    this.checkAllTarget.indeterminate = false
    const checked = event.currentTarget.checked
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = checked
    })

    this.mode = checked ? 'all' : 'none'
    this.selection = []

    this.changeSelection()
  }

  changeSelection() {
    this.modeTargets.forEach((modeTarget) => {
      modeTarget.value = this.mode
    })

    // TODO : if all_but : total_count - id selectionnés
    switch (this.mode) {
      case 'all':
        this.count = this.total
        break
      case 'all_but':
        this.count = this.total - this.selection.length
        break
      case 'some':
        this.count = this.selection.length
        break
      case 'none':
        this.count = 0
        break
      default:
        alert("ERROR")
    }

    this.countTargets.forEach((count) => { count.innerText = Intl.NumberFormat("en").format(this.count) })

    // loads selected ids into hidden select
    this.idsTargets.forEach(function(idEl) {
      idEl.innerHTML = ""
      this.selection.forEach(function(id) {
        const opt = document.createElement('option')
        opt.value = id
        opt.innerHTML = id
        opt.selected = true
        idEl.appendChild(opt)
      })
    }.bind(this))

    if (this.atLeastOneChecked) {
      this.listActionButtonTargets.map(button => button.disabled = false)
    } else {
      this.listActionButtonTargets.map(button => button.disabled = true)
    }
  }

  resetSelection() {
    this.checkAllTarget.indeterminate = false
    this.checkAllTarget.checked = false
    this.checkboxTargets.forEach((checkbox) => {
      checkbox.checked = false
    })
    this.mode = 'none'
    this.selection = []
    this.count = 0
    this.countTarget.innerText = Intl.NumberFormat("en").format(this.count)
  }
}
