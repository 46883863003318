import { Controller } from "@hotwired/stimulus"
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = { url: String }

  connect() {
    var observer = new IntersectionObserver(function (entries) {
      if (entries[0].isIntersecting === true) {
        this.loadURL()
      }
    }.bind(this), {
      rootMargin: "0px"
    })
    observer.observe(this.element)
  }

  loadURL() {
    Rails.ajax({
      type: 'GET',
      url: this.urlValue,
      dataType: 'json',
      success: (data) => {
        this.element.innerHTML = data.html
      }
    })
  }

}
