import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  switchProspect() {
    let type = window.location.pathname
    let search = JSON.parse(localStorage.getItem(`search_${type}`))
    let newType = null

    if (type == '/prospects') {
      newType = '/companies'
    } else if (type == '/companies') {
      newType = '/prospects'
    }
    localStorage.setItem(`search_${newType}`, JSON.stringify(search))
    window.location.replace(newType)
  }

  switchCampaignProspect() {
    let type = window.location.pathname
    let search = JSON.parse(localStorage.getItem(`search_${type}`))
    let newType = null

    if (type.endsWith('/campaign_prospects')) {
      newType = type.replace('/campaign_prospects', '/campaign_companies')
    } else if (type.endsWith('/campaign_companies')) {
      newType = type.replace('/campaign_companies', '/campaign_prospects')
    }
    localStorage.setItem(`search_${newType}`, JSON.stringify(search))
    const newPath = window.location.pathname.replace(type, newType)
    window.location.replace(newPath)
  }
}
