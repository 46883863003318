import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  hideModal(event) {
    event.preventDefault()
    this.element.parentElement.removeAttribute("src")
    this.element.remove()
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.hideModal()
    }
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      this.hideModal()
    }
  }
}
