function Util() {}

Util.toBoolean = function(input) {
  if (input == null) { return false }

  switch (input.toLowerCase()) {
    case "false":
    case "no":
    case "0":
    case "":
      return false
    default:
      return true
  }
}

Util.deepEqual = function(object1, object2) {
  if (object1 === object2) { return true }
  if (typeof(object1) !== typeof(object2)) { return false }

  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = Util.isObject(val1) && Util.isObject(val2)
    if (
      areObjects && !Util.deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}

Util.isObject = function(object) {
  return object != null && typeof object === 'object'
}

export { Util }