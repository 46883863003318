import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["percentage", "number", "total", "button"]

  refreshValues() {
    const totalProspects = parseInt(this.totalTarget.dataset.total)
    let totalDistributed = 0

    this.numberTargets.forEach(function (element, index) {
      const distributionValue = parseInt(element.value)
      this.refreshDistributionPercentage(distributionValue, index)
      if (!isNaN(distributionValue)) {
        totalDistributed += distributionValue
      }
    }.bind(this))

    this.refreshTotalPercentage(totalDistributed, totalProspects)
    const emptyFields = this.numberTargets.filter(input => input.value == "").length
    if (emptyFields && totalDistributed < totalProspects || totalDistributed > totalProspects) {
      this.disableDistribution()
    } else {
      this.enableDistribution()
    }
  }

  calculatePercentage(value) {
    const total = parseInt(this.totalTarget.dataset.total)
    const percentage = value / total * 100
    if (isNaN(percentage)) {
      return 0
    } else {
      return Math.round(percentage)
    }
  }

  refreshDistributionPercentage(distributionValue, index) {
    let newPercentage = 0
    if (!isNaN(distributionValue)) {
      newPercentage = this.calculatePercentage(distributionValue)
    }
    this.percentageTargets[index].innerText = `${newPercentage}%`
  }

  refreshTotalPercentage(totalDistributed, totalProspects) {
    const totalPercentage = this.calculatePercentage(totalDistributed)
    this.totalTarget.innerText = `${totalProspects - totalDistributed} remaining / ${totalProspects} (${totalPercentage}%)`
  }

  disableDistribution() {
    this.totalTarget.classList.add("text-red-600")
    this.buttonTarget.disabled = "disabled"
  }

  enableDistribution() {
    this.totalTarget.classList.remove("text-red-600")
    this.buttonTarget.removeAttribute("disabled")
  }

  limitValues() {
    const min = 0
    const max = parseInt(this.totalTarget.dataset.total)
    this.numberTargets.forEach(element => {
      if (element.value > max) {
        element.value = max
      } else if (element.value < min) {
        element.value = min
      }
    })
  }
}
