import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner", "shown", "total"]

  connect() {
    this.loading()
    this.element.addEventListener('loading', (event) => {
      this.loading()
    })
    this.element.addEventListener('pagination', (event) => {
      this.loaded()
      this.totalTarget.dataset.count = event.detail.total
      this.totalTarget.innerHTML = Intl.NumberFormat("en").format(event.detail.total)
      this.shownTarget.innerHTML = Intl.NumberFormat("en").format(event.detail.shown)
    })
  }

  loading() {
    this.spinnerTarget.classList.remove('hidden')
    this.shownTarget.classList.add('hidden')
  }

  loaded() {
    this.spinnerTarget.classList.add('hidden')
    this.shownTarget.classList.remove('hidden')
  }
}
