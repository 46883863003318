import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['notification']

  notificationTargetConnected(notificationTarget) {
    setTimeout(() => { notificationTarget.classList.remove("translate-x-[110%]") }, 300)
    setTimeout(() => { notificationTarget.classList.add("translate-x-[110%]") }, 6700)
    setTimeout(() => { notificationTarget.remove() }, 7000)
  }

  remove(event) {
    event.preventDefault()
    event.currentTarget.parentElement.classList.add("translate-x-[110%]")
    setTimeout(() => { event.currentTarget.parentElement.remove() }, 300)
  }
}
