import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'valueWrapper',
    'warning',
    'value',
    'column'
  ]

  urlRegex = /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/
  emailRegex = /^[a-zA-Z0-9.!\#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/


  connect() {
    this.checkColumn()
  }

  change(event) {
    this.checkColumn()
  }

  checkColumn() {
    if (!this.hasColumnTarget) return
    if (!this.hasValueWrapperTarget) return

    this.resetColumn()
    if (this.shouldBeAnUrl()) {
      this.setWarning()
    } else if (this.shouldBeAnEmail()) {
      this.setWarning()
    } else if (this.shouldBeAString()) {
      this.setWarning()
    } else {
      this.resetColumn()
    }
  }

  shouldBeAnUrl() {
    return ((this.columnTarget.value.includes('url') || this.columnTarget.value.includes('domain')) && !this.urlRegex.test(this.valueTarget.innerHTML.trim()))
  }

  shouldBeAnEmail() {
    return (this.columnTarget.value.includes('email') && !this.emailRegex.test(this.valueTarget.innerHTML.trim()))
  }

  shouldBeAString() {
    return (
        (
          !this.columnTarget.value.includes('url') &&
            !this.columnTarget.value.includes('domain') &&
              !this.columnTarget.value.includes('email')
        ) && (
          this.urlRegex.test(this.valueTarget.innerHTML.trim()) || this.emailRegex.test(this.valueTarget.innerHTML.trim())
        )
      )
  }

  setWarning() {
    this.warningTarget.classList.remove('hidden')
    this.valueWrapperTarget.classList.add('text-red-500')
    this.valueWrapperTarget.classList.remove('text-gray-400')
  }

  resetColumn() {
    this.warningTarget.classList.add('hidden')
    this.valueWrapperTarget.classList.remove('text-red-500')
    this.valueWrapperTarget.classList.add('text-gray-400')
  }
}
