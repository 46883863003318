import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio"]

  activeClass = 'bg-white'

  connect() {
    this.radioTargets[0].checked = true
    this.radioTargets[0].parentElement.classList.add(this.activeClass)
  }

  change(event) {
    this.radioTargets.forEach((radioTarget) => {
      radioTarget.parentElement.classList.remove(this.activeClass)
    })

    event.target.parentElement.classList.add(this.activeClass)
  }
}
