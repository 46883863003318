// Usage:
//  data :
//    controller: select
//    value_field: field used for the value attribute of each option
//    label_field: field used for the content of each option
//    search_field: field used for the search (default to label field)
//    create: determines if the user is allowed to create new items that aren't in the initial list of options.
//    create_on_blur: if true, when user exits the field (clicks outside of input), a new option is created and selected (if create setting is enabled).
//    no_results: if false, hide the "No results found"
//
//      <%=
//        form.select : fruits,
//          [["Apples", 1], ["Bananas", 2], ["Pears", 3]],
//          {},
//          {
//            class: "",
//            multiple: true,
//            data: {
//              controller: "select",
//              value_field: : id,
//              label_field: : name,
//              search_field: : name,
//              create: true,
//              create_on_blur: true,
//              no_results: false
//            }
//          }
//      %>

// import TomSelect from 'tom-select/dist/js/tom-select.base.js'
import TomSelect from 'tom-select'
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.element[
      (str => {
        return str
          .split('--')
          .slice(-1)[0]
          .split(/[-_]/)
          .map(w => w.replace(/./, m => m.toUpperCase()))
          .join('')
          .replace(/^\w/, c => c.toLowerCase())
      })(this.identifier)
    ] = this

    let options = {
      plugins: ['remove_button'],
      highlight: true,
      maxOptions: 300,
      persist: false
    }

    if (this.element.dataset.filterInteger == 'true') { options.createFilter = (input) => { return !/^\d+$/g.test(input) } }
    if (this.element.dataset.removeValue == 'false') { delete options.plugins }
    if (this.element.dataset.create) { options.create = this.element.dataset.create }
    if (this.element.dataset.labelField) { options.labelField = this.element.dataset.labelField }
    if (this.element.dataset.valueField) { options.valueField = this.element.dataset.valueField }
    if (this.element.dataset.searchField || this.element.dataset.labelField) { options.searchField = this.element.dataset.searchField || this.element.dataset.labelField }
    if (this.element.dataset.sortField) { options.sortField = this.element.dataset.sortField }
    if (this.element.dataset.createOnBlur) { options.createOnBlur = this.element.dataset.createOnBlur }

    if (this.element.dataset.noResults == 'false') {
      options.render = {
        no_results: function(data, escape) {
          return null
        }
      }
    }

    if (this.element.dataset.renderOption) {
      options.render = {
        option: function(data, escape) {
          return data ? data[this.element.dataset.renderOption] : null
        }.bind(this)
      }
    }

    if (this.element.dataset.url) {
      options.loadThrottle = 500
      options.load = (query, callback) => {
        let url = this.element.dataset.url + '?q=' + encodeURIComponent(query)
        fetch(url)
          .then(response => response.json())
          .then(data => {
            if (this.element.dataset.redefinedValue) {
              data.map(item => item.value = item[this.element.dataset.redefinedValue])
            }
            callback(data)
          }).catch(() => {
            callback()
          })
      }
    }

    this.select = new TomSelect(this.element, options)

    if (this.element.dataset.selected) {
      let selectedData = JSON.parse(this.element.dataset.selected)

      if (Array.isArray(selectedData)) {
        selectedData.forEach((item) => {
          this.select.addItem(item, true)
        })
      } else {
        this.select.addItem(selectedData, true)
      }
    }

    if (this.element.dataset.items) {
      JSON.parse(this.element.dataset.items).forEach((item) => {
        this.select.createItem(item)
      })
    }

    if (this.element.dataset.wrapperClass) {
      const wrapperClasses = this.element.dataset.wrapperClass.split(" ")
      this.select.wrapper.classList.add(...wrapperClasses)
    }

    this.select.on('change', (e) => {
      this.element.dispatchEvent(new Event('change'))
    })

    this.select.on('item_add', (e) => {
      this.select.control_input.value = null
    })

    this.select.addOption({
      createFilter: function(input) {
        input = input.toLowerCase()
        return this.grep(this.select.getValue(), function (value) {
          return value.toLowerCase() === input
        }).length == 0
      }
    })
  }

  grep(elems, callback, inv, thisArg) {
    var ret = []
    var retVal
    inv = !!inv;
    for (var i = 0, length = elems.length; i < length; i++) {
      if (i in elems) { // check existance
        retVal = !!callback.call(thisArg, elems[i], i) // set callback this
        if (inv !== retVal) {
          ret.push(elems[i])
        }
      }
    }
    return ret
  }
}
