import { Controller } from "@hotwired/stimulus"
import { DirectUpload } from "@rails/activestorage"
import Papa from 'papaparse'
import Rails from "@rails/ujs"

export default class extends Controller {
  static targets = [
    "file",
    "fileName",
    "close",
    "input",
    "key",
    "headers",
    "progress",
    "loading",
    "form",
    "import",
    "success",
    "control",
    "loadingHeaders"
  ]
  static values = { url: String, headerUrl: String }

  selectFile() {
    const input = this.inputTarget
    const file = input.files[0]

    this.fileTarget.classList.remove("hidden")
    this.fileNameTarget.innerText = file.name
    this.controlTarget.classList.add('hidden')

    const config = {
      preview: 2,
      complete: function (results, file) {
        if (this.hasHeadersTarget) {
          this.buildHeadersForm(results.data[0], results.data[1])
        }
      }.bind(this)
    }
    Papa.parse(file, config)
  }

  buildHeadersForm(headers, firstLine) {
    const headersJSON = JSON.stringify(headers)
    const firstLineJSON = JSON.stringify(firstLine)

    this.loadingHeadersTarget.classList.remove("hidden")

    Rails.ajax({
      type: 'POST',
      url: this.headerUrlValue,
      data: new URLSearchParams({ headers: headersJSON, first_line: firstLineJSON }).toString(),
      dataType: 'json',
      success: (data) => {
        this.loadingHeadersTarget.classList.add("hidden")
        this.headersTarget.innerHTML = data.html
        this.headersTarget.classList.add('scale-y-100')
        this.headersTarget.classList.remove('scale-y-0')
        this.importTarget.disabled = false
      }
    })
  }

  reset(event) {
    event.preventDefault()
    if (this.hasHeadersTarget) {
      this.headersTarget.innerHTML = ""
    }
    if (this.hasFormTarget) {
      this.formTarget.reset()
    }
    this.fileTarget.classList.add("hidden")
    this.fileNameTarget.innerText = ""
    this.controlTarget.classList.remove('hidden')

    this.progressTarget.classList.remove('scale-y-100')
    this.progressTarget.classList.add('scale-y-0')
    this.loadingTarget.style.width = "0%"
    this.loadingTarget.classList.remove('bg-green-400')
    this.loadingTarget.classList.add('bg-orange-400')

    if (this.hasImportTarget) {
      this.importTarget.disabled = true
      this.importTarget.classList.remove('hidden')
    }
    if (this.hasSuccessTarget) {
      this.successTarget.classList.add('hidden')
    }
  }

  upload(e) {
    e.preventDefault()
    const input = this.inputTarget
    const file = input.files[0]
    const url = this.urlValue
    new Uploader(file, url, input, this)
  }
}

class Uploader {
  constructor(file, url, input, controller) {
    this.controller = controller
    this.showProgress()
    this.upload = new DirectUpload(file, url, this)
    this.upload.create((error, blob) => {
      if (error) {
        alert(error)
      } else {
        controller.keyTarget.value = blob.key
        this.launchImport()
      }
    })
  }

  showProgress() {
    this.controller.progressTarget.classList.add('scale-y-100')
    this.controller.progressTarget.classList.remove('scale-y-0')
  }

  updateProgress(percentage) {
    this.controller.loadingTarget.style.width = percentage + "%"
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener("progress",
      event => this.directUploadDidProgress(event))
    request.upload.addEventListener("load",
      event => this.directUploadDidFinish(event))
  }

  directUploadDidProgress(event) {
    const progress = (event.loaded / event.total) * 100
    this.updateProgress(progress)
  }

  directUploadDidFinish(event) {
    this.updateProgress(100)
  }

  launchImport() {
    let fd = new FormData(this.controller.formTarget)
    Rails.ajax({
      type: 'POST',
      url: this.controller.formTarget.action,
      data: fd,
      dataType: 'json',
      success: (data) => {
        this.updateProgress(100)
        this.controller.loadingTarget.classList.add('bg-green-400')
        this.controller.loadingTarget.classList.remove('bg-orange-400')
        setTimeout(() => {
          this.controller.importTarget.classList.add('hidden')
          this.controller.successTarget.classList.remove('hidden')
        }, 200)
      }
    })

  }
}
